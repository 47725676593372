import { Coupon, getEligibilityColor, getEligibilityDescription, getEligibilityName } from 'models/Coupon'
import { useEffect, useMemo, useRef } from 'react'
import CouponIcon from 'components/icons/CouponIcon'
import StaredCouponIcon from 'components/icons/StaredCouponIcon'
import SmileIcon from 'components/icons/SmileIcon'
import { formatDateAsShort, formatTime } from 'utils/datetime'
import DateEventIncomingIcon from 'components/icons/DateEventIncomingIcon'
import DateEventCheckedIcon from 'components/icons/DateEventCheckedIcon'

type CouponModalProps = { coupon?: Coupon; visible: boolean; onClose: () => void }

function CouponModal({ coupon, visible, onClose }: CouponModalProps) {
  const modalRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (!modalRef.current) {
      return
    }
    if (visible) {
      modalRef.current?.showModal()
    } else {
      modalRef.current?.close()
    }
  }, [visible])

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const remainingCoupons = useMemo(() => (coupon ? coupon.max_use - coupon.utilization_count : 0), [coupon])

  const remainingCouponsPercentage = useMemo(
    () => (coupon ? (100 * remainingCoupons) / coupon.max_use : 0).toFixed(1),
    [remainingCoupons]
  )

  const utilizationCountPercentage = useMemo(
    () => (coupon ? (100 * coupon.utilization_count) / coupon.max_use : 0).toFixed(1),
    [remainingCoupons]
  )

  return (
    <dialog
      ref={modalRef}
      id='my_modal_1'
      className='modal'
      onCancel={event => {
        event.preventDefault()
        handleClose()
      }}>
      <div className='modal-box max-w-xl'>
        <form method='dialog'>
          <button className='btn btn-sm btn-circle btn-ghost absolute right-2 top-2' onClick={handleClose}>
            ✕
          </button>
        </form>
        {coupon ? (
          <>
            <div className='flex items-center gap-2'>
              <div className='flex items-center tooltip tooltip-bottom' data-tip={coupon.active ? 'Ativo' : 'Inativo'}>
                <input
                  className='checkbox checkbox-primary cursor-default'
                  type='checkbox'
                  readOnly
                  checked={!!coupon.active}
                />
              </div>
              <h3 className='font-bold text-lg mr-8'>{coupon.code}</h3>
            </div>
            <p className='py-4'>{coupon.description}</p>
            <div className='stats shadow w-full overflow-x-auto mb-2'>
              <div className='stat'>
                <div className='stat-figure text-primary'>
                  <CouponIcon />
                </div>
                <div className='stat-title'>Disponíveis</div>
                <div className='stat-value text-primary'>{remainingCoupons}</div>
                <div className='stat-desc'>{`${remainingCouponsPercentage}% restante para uso`}</div>
              </div>
              <div className='stat'>
                <div className='stat-figure text-success'>
                  <StaredCouponIcon />
                </div>
                <div className='stat-title'>Utilizados</div>
                <div className='stat-value text-success'>{coupon.utilization_count}</div>
                <div className='stat-desc'>{`${utilizationCountPercentage}% aplicados com sucesso`}</div>
              </div>
            </div>
            <div className='stats shadow w-full overflow-x-auto mb-2'>
              <div className='stat'>
                <div className='stat-figure'>
                  <DateEventIncomingIcon />
                </div>
                <div className='stat-title'>Criação</div>
                <div className='stat-value'>{formatDateAsShort(coupon.valid_from)}</div>
                <div className='stat-desc'>Horário de criação: {formatTime(coupon.valid_from)}</div>
              </div>
              <div className='stat'>
                <div className='stat-figure'>
                  <DateEventCheckedIcon />
                </div>
                <div className='stat-title'>Expiração</div>
                <div className='stat-value'>{formatDateAsShort(coupon.valid_until)}</div>
                <div className='stat-desc'>Horário de expiração: {formatTime(coupon.valid_until)}</div>
              </div>
            </div>
            <div className='stats shadow w-full overflow-x-auto'>
              <div className='stat'>
                <div className={`stat-figure text-${getEligibilityColor(coupon.eligibility)}`}>
                  <SmileIcon />
                </div>
                <div className='stat-title'>Elegibilidade</div>
                <div className={`stat-value text-${getEligibilityColor(coupon.eligibility)}`}>
                  {getEligibilityName(coupon.eligibility)}
                </div>
                <div className='stat-desc'>{getEligibilityDescription(coupon.eligibility)}</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='mr-8'>
              <div className='skeleton h-8 w-full mb-4' />
            </div>
            <div className='skeleton h-6 w-full mb-2' />
          </>
        )}
      </div>
      <form method='dialog' className='modal-backdrop'>
        <button onClick={handleClose}>close</button>
      </form>
    </dialog>
  )
}

export default CouponModal
