import logo from 'assets/logo.svg'
import { useCallback, useEffect, useState } from 'react'
import { InvokeCommand } from '@aws-sdk/client-lambda'
import { lambdaClient } from 'service/aws/lambdaClient'
import CouponsTable from 'components/CouponsTable'
import { useParams } from 'react-router-dom'
import EditIcon from 'components/icons/EditIcon'
import ChangeAccountModal, { ChangeAccountModalType } from 'components/ChangeAccountModal'

function Home() {
  const { id } = useParams()

  const [changeAccountModalOpenedType, setChangeAccountModalOpenedType] = useState<ChangeAccountModalType | undefined>()

  const [name, setName] = useState()
  const [coupons, setCoupons] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const getCoupons = useCallback(async () => {
    if (!id) return
    try {
      setCoupons(undefined)
      setError(false)
      setLoading(true)
      const { Payload } = await lambdaClient.send(
        new InvokeCommand({
          FunctionName: 'getCoupomByUserId',
          Payload: JSON.stringify({
            id
          })
        })
      )
      const res = JSON.parse(new TextDecoder('utf-8').decode(Payload))
      const body = JSON.parse(res.body)
      setName(body.name)
      setCoupons(body.coupons)
    } catch (err) {
      console.log('Error', err)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    getCoupons()
  }, [getCoupons])

  return (
    <>
      <div className='Home'>
        <div className='navbar bg-base-100'>
          <img src={logo} className='h-14 w-14 p-2' alt='logo' />
          <a className='btn btn-ghost text-xl' href='/'>
            Cupons PlusBox
          </a>
        </div>

        <div className='px-6'>
          {(() => {
            if (!id) {
              return (
                <div className='hero min-h-96'>
                  <div className='hero-content text-center'>
                    <div className='max-w-md'>
                      <h1 className='text-5xl font-bold text-primary'>{'Boas vindas'}</h1>
                      <article className='prose py-6'>
                        <p>
                          {'Essa é uma plataforma para que você possa acompanhar o desempenho de seus cupons '}
                          <span className='font-bold text-primary'>PlusBox</span>
                          {'. Para acessar seus cupons entre com seu '}
                          <span className='font-bold'>identificador</span>
                          {' usando o botão abaixo ou acesse diretamente o '}
                          <span className='font-bold'>link</span>
                          {' recebido por você.'}
                        </p>
                        <p>
                          {'Caso tenha alguma dúvida, entre em contato conosco através do email '}
                          <a href='mailto:contato@plusbox.com.br'>contato@plusbox.com.br</a>.
                        </p>
                      </article>
                      <button className='btn btn-primary' onClick={() => setChangeAccountModalOpenedType('newLogin')}>
                        Entrar <EditIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )
            } else if (loading) {
              return (
                <>
                  <div className='pt-6 pb-16'>
                    <div className='skeleton h-10 w-1/2 mb-8' />

                    <div className='skeleton h-6 w-full mb-2' />
                    <div className='skeleton h-6 w-full mb-2' />
                    <div className='skeleton h-6 w-1/2 mb-5' />

                    <div className='skeleton h-6 w-full mb-2' />
                    <div className='skeleton h-6 w-1/2 mb-2' />
                  </div>
                  <div className='skeleton h-6 w-full mb-2' />
                  <div className='skeleton h-9 w-full mb-2' />
                  <div className='skeleton h-9 w-full mb-2' />
                  <div className='skeleton h-9 w-full mb-2' />
                  <div className='skeleton h-9 w-full mb-2' />
                </>
              )
            } else if (error) {
              return (
                <div className='hero min-h-96'>
                  <div className='hero-content text-center'>
                    <div className='max-w-md'>
                      <h1 className='text-5xl font-bold text-error'>Conta não encontrada</h1>
                      <article className='prose py-6'>
                        <p>
                          {'Você entrou na plataforma com um identificador de conta inválido. Verifique o '}
                          <span className='font-bold'>link</span> ou <span className='font-bold'>identificador</span>
                          {' recebido por você e tente novamente.'}
                        </p>
                        <p>
                          {'Caso o problema persista, entre em contato conosco através do email '}
                          <a href='mailto:contato@plusbox.com.br'>contato@plusbox.com.br</a>.
                        </p>
                      </article>
                      <div className='flex items-center justify-center gap-2'>
                        <button className='btn btn-outline btn-primary' onClick={() => getCoupons()}>
                          Tentar novamente
                        </button>
                        <button
                          className='btn btn-primary'
                          onClick={() => setChangeAccountModalOpenedType('changeLogin')}>
                          Alterar conta <EditIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            } else if (coupons) {
              return (
                <>
                  {/* <div className='text text-success'>success</div>
                  <div className='text text-success-high-contrast'>success high contrast</div>

                  <div className='h-20' />

                  <div className='badge badge-success'>success</div>
                  <div className='badge badge-success-high-contrast'>success high contrast</div>

                  <div className='h-20' /> */}

                  <article className='prose pt-6 pb-20'>
                    <div className='flex items-start space-x-4'>
                      <h1 className='text-primary'>{`Boas vindas, ${name}!`}</h1>
                      <div className='tooltip' data-tip='Alterar conta'>
                        <button
                          className='btn btn-primary btn-circle'
                          onClick={() => setChangeAccountModalOpenedType('changeLogin')}>
                          <EditIcon />
                        </button>
                      </div>
                    </div>
                    <p>
                      {'Essa é uma plataforma para que você possa acompanhar o desempenho de seus cupons '}
                      <span className='font-bold text-primary'>PlusBox</span>
                      {
                        '. Para mais detalhes a respeito de cada um dos cupons, basta selecionar sua respectiva linha na tabela abaixo.'
                      }
                    </p>
                    <p>
                      {'Caso tenha alguma dúvida, entre em contato conosco através do email '}
                      <a href='mailto:contato@plusbox.com.br'>contato@plusbox.com.br</a>.
                    </p>
                  </article>
                  <CouponsTable coupons={coupons} />
                </>
              )
            }
          })()}
        </div>
      </div>
      <ChangeAccountModal
        type={changeAccountModalOpenedType}
        visible={!!changeAccountModalOpenedType}
        onClose={() => setChangeAccountModalOpenedType(undefined)}
      />
    </>
  )
}

export default Home
