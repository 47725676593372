import { useState } from 'react'
import CouponModal from 'components/CouponModal'
import { Coupon, getEligibilityColor, getEligibilityDescription, getEligibilityName } from 'models/Coupon'
import { formatDateAsFull, formatDateAsShort } from 'utils/datetime'

function CouponsTable({ coupons }: { coupons: Coupon[] }) {
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon>()

  return (
    <>
      <div className='overflow-x-auto h-96'>
        <table className='table table-xs table-pin-rows table-pin-cols'>
          <thead>
            <tr>
              <th></th>
              <td>Ativo</td>
              <td>Disponíveis</td>
              <td>Utilizados</td>
              <td>Uso/pessoa</td>
              <td>Elegibilidade</td>
              <td>Criação</td>
              <td>Expiração</td>
            </tr>
          </thead>
          <tbody>
            {coupons.map(c => (
              <tr className='cursor-pointer bg-white hover' key={c.id} onClick={() => setSelectedCoupon(c)}>
                <th className='bg-inherit'>{c.code}</th>
                <td>
                  <input className='checkbox checkbox-primary' type='checkbox' readOnly checked={!!c.active} />
                </td>
                <td>
                  <div className='badge badge-primary'>{c.max_use - c.utilization_count}</div>
                </td>
                <td>
                  <div className='badge badge-success'>{c.utilization_count}</div>
                </td>
                <td>{c.max_use_per_client}</td>
                <td>
                  <div className='tooltip' data-tip={getEligibilityDescription(c.eligibility)}>
                    <div className={`badge badge-outline badge-sm badge-${getEligibilityColor(c.eligibility)}`}>
                      {getEligibilityName(c.eligibility)}
                    </div>
                  </div>
                </td>
                <td>
                  <div className='tooltip' data-tip={formatDateAsFull(c.valid_from, { showTime: true })}>
                    {formatDateAsShort(c.valid_from)}
                  </div>
                </td>
                <td>
                  <div className='tooltip' data-tip={formatDateAsFull(c.valid_until, { showTime: true })}>
                    {formatDateAsShort(c.valid_until)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CouponModal coupon={selectedCoupon} visible={!!selectedCoupon} onClose={() => setSelectedCoupon(undefined)} />
    </>
  )
}

export default CouponsTable
