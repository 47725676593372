function StaredCouponIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      height='24'
      width='24'
      viewBox='0 -960 960 960'
      className='inline-block w-8 h-8 stroke-current'>
      <path d='m376-318 104-81 104 81-40-128 98-82H522l-42-122-42 122H318l98 82-40 128ZM140-160q-25 0-42.5-17.5T80-220v-132q0-10 5.5-17.5T100-380q31-11 48.5-39.5T166-480q0-31-17.5-60T100-580q-9-3-14.5-10.5T80-608v-132q0-25 17.5-42.5T140-800h680q25 0 42.5 17.5T880-740v132q0 10-5.5 17.5T860-580q-31 11-48.5 40T794-480q0 32 17.5 60.5T860-380q9 3 14.5 10.5T880-352v132q0 25-17.5 42.5T820-160H140Zm0-60h680v-109q-38-26-62-65t-24-86q0-47 24-86t62-65v-109H140v109q39 26 62.5 65t23.5 86q0 47-23.5 86T140-329v109Zm340-260Z' />
    </svg>
  )
}

export default StaredCouponIcon
