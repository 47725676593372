import 'app.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from 'pages/home'

function App() {
  return (
    <div className='font-sans'>
      <BrowserRouter>
        <Routes>
          {['/', '/:id'].map(path => (
            <Route key={path} path={path} element={<Home />} />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
