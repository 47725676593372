import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export type ChangeAccountModalType = 'newLogin' | 'changeLogin'

type ChangeAccountModalProps = { type?: ChangeAccountModalType; visible: boolean; onClose: () => void }

const uuidv4Regex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

function ChangeAccountModal({ type = 'newLogin', visible, onClose }: ChangeAccountModalProps) {
  const { id } = useParams()
  const navigate = useNavigate()
  const modalRef = useRef<HTMLDialogElement>(null)

  const [idInputValue, setIdInputValue] = useState<string>()
  const [idInputError, setIdInputError] = useState(false)

  useEffect(() => {
    if (!id) return
    setIdInputValue(id)
  }, [id])

  useEffect(() => {
    if (visible) setIdInputValue(id)
  }, [visible])

  useEffect(() => {
    if (!modalRef.current) {
      return
    }
    if (visible) {
      modalRef.current?.showModal()
    } else {
      modalRef.current?.close()
    }
  }, [visible])

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleConfirm = () => {
    // TODO: accept url and make a split when confirmed
    if (idInputValue?.match(uuidv4Regex)) {
      handleClose()
      navigate(`/${idInputValue}`)
    } else {
      setIdInputError(true)
    }
  }

  return (
    <dialog
      ref={modalRef}
      id='my_modal_1'
      className='modal'
      onCancel={event => {
        event.preventDefault()
        handleClose()
      }}>
      <div className='modal-box'>
        <form method='dialog'>
          <button className='btn btn-sm btn-circle btn-ghost absolute right-2 top-2' onClick={handleClose}>
            ✕
          </button>
        </form>
        <h3 className='font-bold text-lg'>
          {(() => {
            switch (type) {
              case 'newLogin':
                return 'Entrar'
              case 'changeLogin':
                return 'Alterar conta'
            }
          })()}
        </h3>
        <label className='form-control w-full max-w-xs'>
          <div className='label'>
            <span className='label-text'>
              {(() => {
                switch (type) {
                  case 'newLogin':
                    return 'Insira o identificador de sua conta.'
                  case 'changeLogin':
                    return 'Insira outro identificador para trocar de conta.'
                }
              })()}
            </span>
          </div>
          <div className='join'>
            <input
              className='input input-bordered input-primary join-item w-full max-w-xs'
              type='text'
              placeholder='Insira seu identificador'
              value={idInputValue}
              onFocus={() => setIdInputError(false)}
              onChange={event => setIdInputValue(event.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleConfirm()
                }
              }}
            />
            <button className='btn btn-primary join-item rounded-r-full' onClick={handleConfirm}>
              Confirmar
            </button>
          </div>
          <div className='label'>
            {idInputError && <span className='label-text-alt text-error'>Identificador inválido.</span>}
          </div>
        </label>
      </div>
      <form method='dialog' className='modal-backdrop'>
        <button onClick={handleClose}>close</button>
      </form>
    </dialog>
  )
}

export default ChangeAccountModal
