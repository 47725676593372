function DateEventIncomingIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      height='24'
      width='24'
      viewBox='0 -960 960 960'
      className='inline-block w-8 h-8 stroke-current'>
      <path d='M566-80v-60h214v-430H180v220h-60v-410q0-24 18-42t42-18h65v-60h65v60h340v-60h65v60h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H566ZM320-14l-42-42 113-114H50v-60h341L278-344l42-42 186 186L320-14ZM180-630h600v-130H180v130Zm0 0v-130 130Z' />
    </svg>
  )
}

export default DateEventIncomingIcon
