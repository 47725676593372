export type Coupon = {
  id: number
  code: string
  description: string
  active: number
  max_use: number
  max_use_per_client: number
  eligibility: Elegibility
  utilization_count: number
  valid_from: string
  valid_until: string
}

export type Elegibility = 'all' | 'specific_clients'

export function getEligibilityColor(eligibility: Elegibility) {
  switch (eligibility) {
    case 'all':
      return 'success'
    case 'specific_clients':
      return 'info'
    default:
      return 'neutral'
  }
}

export function getEligibilityName(eligibility: Elegibility) {
  switch (eligibility) {
    case 'all':
      return 'Todos'
    case 'specific_clients':
      return 'Específicos'
    default:
      return eligibility
  }
}

export function getEligibilityDescription(eligibility: Elegibility) {
  switch (eligibility) {
    case 'all':
      return 'Todos os clientes podem utilizar esse cupom'
    case 'specific_clients':
      return 'Apenas alguns clientes específicos podem utilizar esse cupom'
    default:
      return eligibility
  }
}
