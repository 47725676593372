export function formatDateAsFull(dateStr: string, options?: { showTime?: boolean }) {
  const showTime = options?.showTime
  try {
    if (!dateStr) throw new Error()
    return new Date(Date.parse(dateStr.replace(' ', 'T'))).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      ...(showTime
        ? {
            hour: '2-digit',
            minute: '2-digit'
          }
        : {})
    })
  } catch (error) {
    return '-'
  }
}

export function formatDateAsShort(dateStr: string, options?: { showTime?: boolean }) {
  const showTime = options?.showTime
  try {
    if (!dateStr) throw new Error()
    return new Date(Date.parse(dateStr.replace(' ', 'T'))).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      ...(showTime
        ? {
            hour: '2-digit',
            minute: '2-digit'
          }
        : {})
    })
  } catch (error) {
    return '-'
  }
}

export function formatTime(dateStr: string) {
  try {
    if (!dateStr) throw new Error()
    return new Date(Date.parse(dateStr.replace(' ', 'T')))
      .toLocaleTimeString('pt-BR', {
        timeStyle: 'short'
      })
      .replace(':', 'h')
  } catch (error) {
    return '-'
  }
}
